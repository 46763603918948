import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { dialogConfig } from "@app/core/const/dialog.const";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { PropertyModel } from "@app/pages/property/+data-access";
import {
  AttachmentModel,
  RoamToastrService,
} from "@app/pages/task/+data-access";
import { ViolationReqBody } from "@app/pages/violation/+data-access";
import {
  ViolationCategory,
  ViolationLetterTemplateModel,
  ViolationModel,
  ViolationUnitModel,
} from "@app/pages/violation/+data-access/violation.model";
import { ButtonModule } from "@app/shared/components/button/button.module";
import { FileUploaderComponent } from "@app/shared/components/file-uploader";
import { RoamDatepickerComponent } from "@app/shared/components/roam-datepicker/roam-datepicker.component";
import { RoamInputComponent } from "@app/shared/components/roam-input/roam-input.component";
import { RoamRadioCardComponent } from "@app/shared/components/roam-radio-card/roam-radio-card.component";
import { RoamSelectComponent } from "@app/shared/components/roam-select/roam-select.component";
import { RoamTextAreaComponent } from "@app/shared/components/roam-text-area/roam-text-area.component";
import { SharingEmailComponent } from "@app/shared/components/sharing-email/sharing-email.component";
import { IRadio } from "@app/shared/interfaces/roam-radio.inteface";
import { AssociationService } from "@app/shared/services/association.service";
import { ViolationService } from "@app/shared/services/violation.service";
import {
  catchError,
  finalize,
  of,
  map,
  merge,
  startWith,
  switchMap,
  tap,
} from "rxjs";
import { ComplianceStatuses } from "../../shared/const/compliance.const";
import { InspectionPreviewLetterComponent } from "../inspection-preview-letter/inspection-preview-letter.component";
import { RoamAclService } from "@app/core/access-control/+data-access";

export interface DropdownItem {
  id: string;
  name: string;
  label?: string;
  value?: string;
  icon?: string;
}

@Component({
  standalone: true,
  selector: "app-add-new-violation",
  styleUrl: "./add-new-violation.component.scss",
  templateUrl: "./add-new-violation.component.html",
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    RoamInputComponent,
    RoamSelectComponent,
    RoamDatepickerComponent,
    SharingEmailComponent,
    FileUploaderComponent,
    RoamRadioCardComponent,
    RoamTextAreaComponent,
    MatProgressSpinnerModule,
    MatProgressBarModule,
  ],
})
export class AddNewViolationComponent implements OnInit, AfterViewInit {
  #destroyRef = inject(DestroyRef);
  ref = inject(MatDialogRef);

  readonly acl = inject(RoamAclService);
  protected userConfig = inject(UserConfigStore);
  protected fb = inject(FormBuilder);
  protected dialog = inject(MatDialog);
  protected associationService = inject(AssociationService);
  protected violationService = inject(ViolationService);
  protected dialogData = inject(MAT_DIALOG_DATA);
  protected toastr = inject(RoamToastrService);
  protected attachments = signal<AttachmentModel[]>([]);
  protected propertyId = signal<string>("");
  protected modelConfig = computed(() => {
    return {
      propertyId: this.propertyId(),
      modelId: this.violation?.id || crypto.randomUUID(),
      model: "violation",
    };
  });

  protected opts = {
    properties: signal<DropdownItem[]>([]),
    units: signal<DropdownItem[]>([]),
    categories: signal<IRadio[]>([]),
    issues: signal<any[]>([]),
    letterTemplates: signal<DropdownItem[]>([]),
    statuses: signal(ComplianceStatuses),
  } as const;

  protected loaders = {
    propertiesLoading: signal(false),
    unitsLoading: signal(false),
    typesLoading: signal(false),
    formSubmitting: signal(false),
  };

  protected readonly today = new Date();

  readonly form = this.fb.group({
    propertyId: ["", Validators.required],
    unitId: ["", Validators.required],
    inspectionDate: [this.today.toISOString(), Validators.required],
    categoryId: ["", Validators.required],
    inspectionIssues: this.fb.control<string[]>([], Validators.required),
    letterTemplateId: ["", Validators.required],
    status: this.fb.control<number | null>(null, Validators.required),
    text: ["", Validators.required],
    isIssueFine: [false],
    fineDueDate: [""],
    fineAmount: [0],
    fineMemo: [""],
    isDeadline: [false],
    deadlineDate: [""],
  });

  get controls() {
    return this.form.controls;
  }

  get title() {
    return this.dialogData?.title || "Add Violation";
  }

  get violation(): ViolationModel | null {
    return this.dialogData?.violation || null;
  }

  submitCreate = (body: ViolationReqBody.AddOne, close = false) => {
    this.loaders.formSubmitting.set(true);
    this.violationService
      .createViolation(body)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        error: () => {
          this.loaders.formSubmitting.set(false);
          this.toastr.danger("Failed to create new violation!");
        },
        next: resp => {
          this.loaders.formSubmitting.set(false);
          this.toastr.success("Violation created successfully!");
          close && this.ref.close(resp);
        },
      });
  };

  submitPatch = (
    violationId: string,
    body: ViolationReqBody.PatchOne,
    close = false
  ) => {
    this.loaders.formSubmitting.set(true);
    this.violationService
      .updateViolation(violationId, body)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        error: () => {
          this.loaders.formSubmitting.set(false);
          this.toastr.danger("Failed to update the violation!");
        },
        next: resp => {
          this.loaders.formSubmitting.set(false);
          this.toastr.success("Violation updated successfully!");
          close && this.ref.close(resp);
        },
      });
  };

  save(closeOnSuccess = false): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const existing = this.violation;
    const v = this.form.getRawValue();
    if (!existing) {
      this.submitCreate(
        {
          propertyId: v.propertyId || this.propertyId(),
          categoryId: v.categoryId || "",
          unitId: v.unitId || "",
          letterTemplateId: v.letterTemplateId || "",
          date: v.inspectionDate || new Date().toISOString(),
          text: v.text || "",
          inspectionIssues: v.inspectionIssues || [],
          status: v.status || 0,
        },
        closeOnSuccess
      );
    } else {
      this.submitPatch(
        existing.id,
        {
          propertyId: v.propertyId || this.propertyId(),
          categoryId: v.categoryId || "",
          unitId: v.unitId || "",
          date: v.inspectionDate || new Date().toISOString(),
          status: v.status || 0,
        },
        closeOnSuccess
      );
    }
  }

  protected previewInspection = (): void => {
    this.dialog.open(InspectionPreviewLetterComponent, {
      ...dialogConfig.addDialogInspection,
      hasBackdrop: false,
      data: this.form.getRawValue(),
    });
  };

  ngAfterViewInit(): void {
    const units$ = this.form.controls.propertyId.valueChanges.pipe(
      startWith(this.propertyId()),
      tap(() => this.loaders.unitsLoading.set(true)),
      switchMap(value =>
        this.associationService.searchUnits({ property: value }).pipe(
          finalize(() => this.loaders.unitsLoading.set(false)),
          map(resp => resp?.data || []),
          catchError(() => of([])),
          tap({
            next: (items: ViolationUnitModel[]) => {
              this.opts.units.set(items.map(({ id, name }) => ({ id, name })));
            },
          })
        )
      )
    );

    const issues$ = this.form.controls.propertyId.valueChanges.pipe(
      startWith(this.propertyId()),
      tap(() => this.loaders.typesLoading.set(true)),
      switchMap(value =>
        this.violationService.getViolationSubcategories(value || "").pipe(
          finalize(() => this.loaders.typesLoading.set(false)),
          catchError(() => of([])),
          tap({
            next: (items: ViolationCategory[]) => {
              const opts = items.map(x => {
                return {
                  id: x.id,
                  name: x.name || "",
                };
              });
              this.opts.issues.set(opts);
            },
          })
        )
      )
    );

    const templates$ = this.form.controls.propertyId.valueChanges.pipe(
      startWith(this.propertyId()),
      tap(() => this.loaders.typesLoading.set(true)),
      switchMap(value =>
        this.violationService.getTemplates(value || "").pipe(
          finalize(() => this.loaders.typesLoading.set(false)),
          catchError(() => of([])),
          tap((items: ViolationLetterTemplateModel[]) => {
            this.opts.letterTemplates.set(
              items.map(x => {
                return {
                  id: x.id,
                  name: x.name,
                  label: x.name,
                  value: x.id,
                };
              })
            );
          })
        )
      )
    );

    merge(units$, issues$, templates$)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }

  protected initOptions = () => {
    this.loaders.propertiesLoading.set(true);
    const initProperties$ = this.associationService
      .searchAssociations<any>({ page: 1, limit: 100 })
      .pipe(
        finalize(() => this.loaders.propertiesLoading.set(false)),
        map(resp => resp?.data || []),
        catchError(() => of([])),
        tap(items => {
          this.opts.properties.set(
            items.map((item: PropertyModel) => {
              return {
                id: item.id,
                name: item.name,
              };
            })
          );
        })
      );

    this.loaders.typesLoading.set(true);
    const initCategories$ = this.violationService.getCategories().pipe(
      finalize(() => this.loaders.typesLoading.set(false)),
      catchError(() => of([])),
      tap((items: ViolationCategory[]) => {
        this.opts.categories.set(
          items.map(cat => {
            return {
              id: cat.id,
              name: cat.name || "",
              label: cat.name || "",
              value: cat.id,
              icon: cat.iconUrl || "",
            };
          })
        );
      })
    );

    merge(initProperties$, initCategories$)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  };

  protected setupEditForm = () => {
    const v = this.violation;
    if (!v) return;
    this.attachments.set(v.files || []);
    this.form.patchValue({
      status: v.status || null,
      unitId: v.unitId || "",
      text: v.description || "",
      inspectionDate: v.date || "",
      categoryId: v.categoryId || "",
      letterTemplateId: v.nextViolationLetterTemplate?.id || "",
      isIssueFine: v.nextViolationLetterTemplate?.isFine || false,
      inspectionIssues: v.lastLetterIssues?.map(x => x.id) || [],
    });
  };

  ngOnInit(): void {
    this.initOptions();
    this.setupEditForm();
  }

  constructor() {
    const globalAssociationId = toSignal(
      this.userConfig.selectedAssociationId$,
      { initialValue: "" }
    );
    const propertyId =
      this.violation?.propertyId || globalAssociationId() || "";
    this.propertyId.set(propertyId);
    this.form.controls.propertyId.setValue(propertyId);
  }
}
